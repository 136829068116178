import React from 'react'
import {
  Box,
  Icon,
  HStack,
  Text,
  useColorModeValue,
  Container,
  VStack,
} from '@chakra-ui/react'
import GoogleMapReact from 'google-map-react'
import { MdLocationOn, MdEmail, MdPhone } from 'react-icons/md'

const ContactCard = () => {
  const defaultProps = {
    center: {
      lat: 47.5117481,
      lng: 19.0362146,
    },
    zoom: 15,
  }

  return (
    <>
      <Container maxW={'7xl'} p="12">
        <Box
          marginTop={{ base: '1', sm: '5' }}
          display="flex"
          flexDirection={{ base: 'column', sm: 'row' }}
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flex="1"
            marginRight="3"
            position="relative"
            alignItems="center"
          >
            <Box
              width={{ base: '100%', sm: '85%' }}
              zIndex="2"
              marginLeft={{ base: '0', sm: '5%' }}
              marginTop="5%"
              style={{ height: '35vh' }}
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyAGOn3bY1MaBR1fFKcMbzXyjEjg-3NO14I',
                }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
              />
            </Box>
            <Box zIndex="1" width="100%" position="absolute" height="100%">
              <Box
                bgGradient={useColorModeValue(
                  'radial(red.600 1px, transparent 1px)',
                  'radial(red.300 1px, transparent 1px)',
                )}
                backgroundSize="20px 20px"
                opacity="0.5"
                height="100%"
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
            marginTop={{ base: '3', sm: '0' }}
          >
            <HStack style={{ padding: 10 }} align={'top'}>
              <Box color={'green.400'} px={2}>
                <Icon color="red.400" w={50} h={50} as={MdLocationOn} />
              </Box>
              <VStack align={'start'}>
                <Text fontWeight={600}>Elite Home Management Kft.</Text>
                <Text color={'gray.600'}>
                  1136 Budapest, Pannónia utca 11. fszt. 5
                </Text>
              </VStack>
            </HStack>
            <HStack style={{ padding: 10 }} align={'top'}>
              <Box color={'green.400'} px={2}>
                <Icon color="red.400" w={50} h={50} as={MdPhone} />
              </Box>
              <VStack justify="center" align={'center'}>
                <Text fontWeight={600}>+36 20 669 2884</Text>
              </VStack>
            </HStack>
            <HStack style={{ padding: 10 }} align={'top'}>
              <Box color={'green.400'} px={2}>
                <Icon color="red.400" w={50} h={50} as={MdEmail} />
              </Box>
              <VStack justify="center" align={'center'}>
                <Text fontWeight={600}>g.gabor@elitehome.work</Text>
              </VStack>
            </HStack>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default ContactCard
